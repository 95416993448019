import { types, applySnapshot, getSnapshot } from 'mobx-state-tree'
import { CountryModel } from 'stores/models'
import { enCulture, kgCulture, ruCulture, swCulture, tjCulture } from '../../utils/languages'

const AppStore = types
  .model('AppStore')
  .props({
    _currentLanguage: types.optional(
      types.union(types.literal(ruCulture), types.literal(enCulture), types.literal(kgCulture), types.literal(tjCulture), types.literal(swCulture)),
      enCulture,
    ),
    _currentCountry: types.optional(CountryModel, {
      id: null,
      name: 'Not initialized',
    }),
  })
  .actions(self => {
    const setLanguage = language => {
      self._currentLanguage = language
    }

    const setCountry = country => {
      applySnapshot(self._currentCountry, country)
    }

    return {
      setLanguage,
      setCountry,
    }
  })
  .views(self => ({
    get currentLanguage() {
      return self._currentLanguage
    },

    get currentCountry() {
      return getSnapshot(self._currentCountry)
    },
  }))

export default AppStore
