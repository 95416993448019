import { IntlProvider, ReactIntlErrorCode } from 'react-intl'
import { observer } from 'mobx-react'
import langRu from './translations/ru.json'
import langEn from './translations/en.json'
import langKg from './translations/kg.json'
import langTj from './translations/tj.json'
import langTz from './translations/tz.json'
import { createContext, useContext, useEffect } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { useStore } from 'hooks'
import { ruCulture, enCulture, kgCulture, tjCulture, swCulture } from './utils/languages'

const messages = {
  [ruCulture]: langRu,
  [enCulture]: langEn,
  [kgCulture]: langKg,
  [tjCulture]: langTj,
  [swCulture]: langTz,
}

const IntlContext = createContext({
  switchLanguage: lang => {},
})

const IntlProviderWrapper = observer(({ children }) => {
  const appStore = useStore('appStore')

  useEffect(() => {
    setDefaultLocale(appStore.currentLanguage)
  }, [appStore.currentLanguage])

  const switchLanguage = lang => {
    appStore.setLanguage(lang)
  }

  const lang = appStore.currentLanguage || enCulture

  return (
    <IntlContext.Provider value={{ switchLanguage }}>
      <IntlProvider
        locale={lang}
        messages={messages[lang]}
        defaultLocale={lang}
        onError={err => {
          if (err.code === ReactIntlErrorCode.MISSING_DATA) {
            return
          }
          console.error(err)
        }}
        key={lang}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
})

const useIntlContext = () => useContext(IntlContext)

export { IntlContext, useIntlContext }
export default IntlProviderWrapper
